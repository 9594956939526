





























































































import { Component, Vue } from "vue-property-decorator";
import axios, { AxiosResponse } from "axios";

const httpClient = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  withCredentials: true,
});

interface AnalogInput {
  location: string;
  unit: string;
  value: number;
}

interface BinaryOutput {
  action: string;
  value: boolean;
  objectId: number;
}

interface Status {
  analogInputs: Array<AnalogInput>;
  binaryOutputs: Array<BinaryOutput>;
}

@Component({})
export default class Home extends Vue {
  status: Status = {
    analogInputs: [],
    binaryOutputs: [],
  };

  code = "";
  isAuthenticated = false;
  authenticationFailed = false;

  async created(): Promise<void> {
    await httpClient
      .get("/context")
      .then(() => {
        this.isAuthenticated = true;
      })
      .catch(() => {
        this.isAuthenticated = false;
      });

    await this.refresh();
  }

  async mounted(): Promise<void> {
    window.setInterval(() => {
      if (this.isAuthenticated) {
        this.refresh();
      }
    }, 10000);
  }

  async login(): Promise<void> {
    if (this.isAuthenticated) {
      return;
    }
    await httpClient
      .post(`/login/${this.code}`)
      .then(() => {
        this.isAuthenticated = true;
        this.authenticationFailed = false;
      })
      .catch(() => {
        this.isAuthenticated = false;
        this.authenticationFailed = true;
      });

    await this.refresh();
  }

  async refresh(): Promise<void> {
    if (!this.isAuthenticated) {
      return;
    }

    await this.getStatus().then((response) => {
      this.status = response.data;
    });
  }

  async getStatus(): Promise<AxiosResponse> {
    return httpClient.get("/status");
  }

  async toggle(objectId: number): Promise<void> {
    await httpClient.post(`/toggle/${objectId}`);
    await this.refresh();
  }
}
